import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import styles from './ImageCaption.module.scss';

const ImageCaption = ({ id, caption, title, credits, modifiers = [] }) => {
    const { t } = useTranslation('common');
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = (e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };

    // Prevent unintentional image focus on Safari
    // on button click
    const onMouseDown = (e) => {
        e.preventDefault();
    };

    const classes = classNames(
        styles['ImageCaption'],
        { [styles['ImageCaption--Open']]: isOpen },
        modifiers
    );

    return (
        <div className={classes}>
            <div id={id} className={styles['ImageCaption__Wrap']}>
                {title && (
                    <div className={styles['ImageCaption__Title']}>{title}</div>
                )}
                {caption && (
                    <div className={styles['ImageCaption__Caption']}>
                        {caption}
                    </div>
                )}
                {credits && (
                    <div className={styles['ImageCaption__Credits']}>
                        {t('imageCaption.photo')} {credits}
                    </div>
                )}
            </div>
            <button
                aria-controls={id}
                aria-expanded={isOpen}
                className={styles['ImageCaption__Button']}
                onClick={toggleOpen}
                onMouseDown={onMouseDown}>
                <span className="sr-only">
                    {isOpen ? t('imageCaption.close') : t('imageCaption.open')}
                </span>
                {!isOpen && (
                    <span
                        className={styles['ImageCaption__Icon']}
                        aria-hidden={true}>
                        i
                    </span>
                )}
            </button>
        </div>
    );
};

ImageCaption.propTypes = {
    title: PropTypes.string,
    caption: PropTypes.node,
    credits: PropTypes.node,
    modifiers: PropTypes.array,
    id: PropTypes.string,
};

ImageCaption.defaultProps = {
    id: 'image-0',
    title: '',
    caption: '',
    credits: '',
    modifiers: [],
};

export default ImageCaption;
