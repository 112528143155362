import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from 'next/image';
import styles from './BackgroundImage.module.scss';

const BackgroundImage = ({
    alt = '',
    src = '',
    sizes = null,
    credits = null,
    quality = 75,
    width = null,
    height = null,
    focal = null,
    loadedCallback = () => {},
    shouldLazyLoad = true,
    fill = true,
    hasLogo,
}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [objectFitSupported, setObjectFitSupported] = useState(true);

    useEffect(() => {
        if ('objectFit' in document.documentElement.style === false) {
            setObjectFitSupported(false);
            setIsLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (isLoaded) {
            loadedCallback();
        }
    }, [isLoaded, loadedCallback]);

    const style = { backgroundImage: `url(${src})` };
    const loading = shouldLazyLoad ? 'lazy' : undefined;
    const isPriority = !shouldLazyLoad;

    const classes = classNames(
        styles['BackgroundImage'],
        { [styles['BackgroundImage--Loaded']]: isLoaded || isPriority },
        { [styles['BackgroundImage--Fallback']]: !objectFitSupported },
        { [styles['BackgroundImage--HasLogo']]: hasLogo }
    );

    let imageProps = {};

    if (!fill) {
        imageProps.width = width;
        imageProps.height = height;
    }

    return (
        <figure className={classes}>
            {objectFitSupported && src ? (
                <Image
                    alt={alt}
                    className={styles['BackgroundImage__Image']}
                    onLoad={() => setIsLoaded(true)}
                    src={src}
                    sizes={sizes}
                    loading={loading}
                    priority={isPriority}
                    quality={quality}
                    fill={fill}
                    style={{
                        objectPosition: focal
                            ? `${focal.x} ${focal.y}`
                            : '50% 50%',
                    }}
                    {...imageProps}
                />
            ) : (
                <div
                    className={styles['BackgroundImage__Image']}
                    style={style}
                    title={credits}
                />
            )}
        </figure>
    );
};

BackgroundImage.propTypes = {
    alt: PropTypes.string,
    src: PropTypes.string,
    sizes: PropTypes.string,
    credits: PropTypes.string,
    width: PropTypes.number,
    focal: PropTypes.object,
    height: PropTypes.number,
    quality: PropTypes.number,
    shouldLazyLoad: PropTypes.bool,
    loadedCallback: PropTypes.func,
    fill: PropTypes.bool,
};

export default BackgroundImage;
