import React, { useState, useRef, useContext } from 'react';

import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';
import BackgroundImage from '../BackgroundImage';
import ImageCaption from '../ImageCaption';

import { serializeImage } from '../../utils/SerializeImage';
import useMediaQuery from '../../utils/useMediaQuery';
import isEmpty from '../../utils/isEmpty';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { breakpoints } from '../../constants';
import { CookieStateContext } from '../../containers/BasePage/state';
import OriginalLogo from '../../public/img/logotype-visit-original-sweden.svg';

import Icon from '../Icon';
import Button from '../Button';
import Image from 'next/image';

import styles from './Hero.module.scss';

const Hero = ({
    title,
    text,
    longTitle,
    ctaUrl,
    image,
    videoUrl,
    clean,
    pageType,
    mapDocument,
    mapLabel,
    textAlignment,
    hideNavigation,
    isBelowBreadcrumbs,
    hasLogo,
}) => {
    const { t } = useTranslation('common');
    const { state } = useContext(CookieStateContext) || {};
    const [imageLoaded, setImageLoaded] = useState(true);
    const [videoStarted, setVideoStarted] = useState(false);
    const heroRef = useRef(null);
    const isCampaign = pageType === 'CampaignPage';
    const sizes = '(max-width: 768px) 1280px, 1980px';

    const isMobile = useMediaQuery({
        query: `(max-width: ${breakpoints.m}px)`,
    });

    if (isEmpty(image)) {
        return null;
    }

    const cookieConsent = state?.categories?.includes('targeting');

    const serializedImage = serializeImage(image, true);

    const scrollDown = () => {
        if (typeof window !== 'object') {
            return;
        }
        const heroHeight = heroRef.current.scrollHeight;
        window.scroll({ top: heroHeight, behavior: 'smooth' });
    };

    return (
        <React.Fragment>
            <header
                ref={heroRef}
                className={classNames(styles['Hero'], {
                    [styles['Hero--Text']]: title,
                    [styles['Hero--ImageLoaded']]: imageLoaded,
                    [styles['Hero--Clean']]: clean,
                    [styles['Hero--HomePage']]: pageType == 'HomePage',
                    [styles['Hero--NoCaption']]: !image.caption,
                    [styles['Hero--Campaign']]: isCampaign,
                    [styles['Hero--HideNavigation']]: hideNavigation,
                    [styles['Hero--BelowBreadcrumbs']]: isBelowBreadcrumbs,
                    [styles[`Hero--Align${textAlignment}`]]: !!textAlignment,
                })}>
                <div className={styles['Hero__Container']}>
                    {!isMobile && videoUrl && cookieConsent !== undefined && (
                        <div
                            className={classNames(styles['Hero__Video'], {
                                [styles['Hero__Video--Playing']]: videoStarted,
                            })}>
                            <Vimeo
                                className={styles['Hero__VideoPlayer']}
                                video={videoUrl}
                                muted={true}
                                controls={false}
                                volume={0}
                                autoplay={true}
                                height={'100%'}
                                width={'100%'}
                                title={''}
                                background={true}
                                onPlay={() => setVideoStarted(true)}
                                onEnd={() => setVideoStarted(false)}
                                dnt={!cookieConsent}
                            />
                        </div>
                    )}
                    {serializedImage && (
                        <BackgroundImage
                            loadedCallback={() => setImageLoaded(true)}
                            shouldLazyLoad={false}
                            priority={true}
                            {...serializedImage}
                            sizes={sizes}
                            hideAlt={true}
                            hasLogo={hasLogo}
                        />
                    )}
                    {title && !clean && (
                        <div className={styles['Hero__Content']}>
                            {hasLogo ? (
                                <h1 className={styles['Hero__LogoTitle']}>
                                    <div className={styles['Hero__Logo']}>
                                        <Image
                                            src={OriginalLogo.src}
                                            alt={longTitle || title}
                                            objectFit='contain'
                                            width={OriginalLogo.width}
                                            height={OriginalLogo.height}
                                            priority={true}
                                            unoptimized={true}
                                        />
                                    </div>
                                    {text && (
                                        <div className={styles['Hero__Text']}>
                                            {text}
                                        </div>
                                    )}
                                </h1>
                            ) : (
                                <>
                                    <h1
                                        className={styles['Hero__Title']}
                                        dangerouslySetInnerHTML={{
                                            __html: longTitle || title,
                                        }}
                                    />
                                    {text && (
                                        <div className={styles['Hero__Text']}>
                                            {text}
                                        </div>
                                    )}
                                </>
                            )}

                            {ctaUrl && (
                                <div className={styles['Hero__CTA']}>
                                    <Button
                                        link={ctaUrl}
                                        text={t('hero.readMore')}
                                        type={'Primary'}
                                        icon="Arrow"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {image.caption && (
                        <ImageCaption
                            id={`image-${image.id}`}
                            title={image.title}
                            caption={image.caption}
                            credits={isCampaign ? image.credits : undefined}
                        />
                    )}
                    {mapDocument && (
                        <div className={styles['Hero__MapImageWrapper']}>
                            <div className={styles['Hero__MapImage']}>
                                <figure>
                                    <img src={mapDocument} alt={mapLabel} />
                                    {mapLabel && (
                                        <figcaption>{mapLabel}</figcaption>
                                    )}
                                </figure>
                            </div>
                        </div>
                    )}
                </div>

                {(isCampaign || (clean && !isMobile)) && (
                    <button
                        onClick={scrollDown}
                        className={styles['Hero__ScrollDown']}>
                        <span className="sr-only">
                            {t('hero.scrollToContent')}
                        </span>
                        <Icon
                            className={styles['Hero__ScrollDown__Icon']}
                            name="IconArrow"
                        />
                    </button>
                )}
            </header>
            {!isCampaign && image.credits && (
                <div className={styles['Hero__Credits']}>
                    {`${t('hero.photo')}: ${image.credits}`}
                </div>
            )}
        </React.Fragment>
    );
};

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    longTitle: PropTypes.string,
    image: PropTypes.object,
    pageType: PropTypes.string,
    videoUrl: PropTypes.string,
    ctaUrl: PropTypes.string,
    clean: PropTypes.bool,
    mapDocument: PropTypes.string,
    mapLabel: PropTypes.string,
    textAlignment: PropTypes.string,
    hideNavigation: PropTypes.bool,
    isBelowBreadcrumbs: PropTypes.bool,
};

Hero.defaultProps = {
    title: '',
    longTitle: '',
    videoUrl: '',
    clean: false,
    image: {},
    mapDocument: '',
    mapLabel: '',
    textAlignment: '',
    hideNavigation: false,
    isBelowBreadcrumbs: false,
};

export default Hero;
